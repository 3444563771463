import { createConsumer } from '@rails/actioncable'
import { RedirectController } from '~/dashboard/controllers/redirect_controller.js'

export default class extends RedirectController {
  static values = { id: String }

  connect () {
    const cable = createConsumer()
    cable.subscriptions.create(
      {
        channel: 'PaymentChannel',
        payment_id: this.idValue
      },
      {
        received: (data) => {
          this.redirectTo(data.url)
        }
      }
    )
  }
}
