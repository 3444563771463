import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['canvas', 'image']
  static values = {
    canvasWidth: Number,
    canvasHeight: Number,
    gap: Number,
    dots: Number
  }

  connect () {
    this.setupCanvas()
    this.drawOriginalImage()
    this.drawDots()
    this.addMouseEffect()
    this.haloDisplayed = false
  }

  setupCanvas () {
    this.ctx = this.canvasTarget.getContext('2d')
    this.particleCanvas = document.createElement('canvas')
    this.particleCanvas.width = this.canvasWidthValue
    this.particleCanvas.height = this.canvasHeightValue
    this.particleCtx = this.particleCanvas.getContext('2d')
  }

  drawOriginalImage () {
    const factor = Math.min(this.canvasWidthValue / this.imageTarget.naturalWidth, this.canvasHeightValue / this.imageTarget.naturalHeight)
    this.scaledWidth = this.imageTarget.naturalWidth * factor
    this.scaledHeight = this.imageTarget.naturalHeight * factor
    this.centerX = (this.canvasWidthValue - this.scaledWidth) / 2
    this.centerY = (this.canvasHeightValue - this.scaledHeight) / 2

    this.ctx.drawImage(this.imageTarget, this.centerX, this.centerY, this.scaledWidth, this.scaledHeight)
  }

  drawDots () {
    const totalDots = this.dotsValue
    const gap = this.gapValue || Math.sqrt((this.canvasTarget.width * this.canvasTarget.height) / totalDots)

    const factor = Math.min(
      (this.canvasTarget.width - gap) / this.imageTarget.naturalWidth,
      (this.canvasTarget.height - gap) / this.imageTarget.naturalHeight
    ) - 0.01
    const scaledWidth = this.imageTarget.naturalWidth * factor
    const scaledHeight = this.imageTarget.naturalHeight * factor
    const centerX = (this.canvasTarget.width - scaledWidth) / 2
    const centerY = (this.canvasTarget.height - scaledHeight) / 2

    this.particleCtx.drawImage(this.imageTarget, centerX, centerY, scaledWidth, scaledHeight)

    const imageData = this.particleCtx.getImageData(0, 0, this.canvasTarget.width, this.canvasTarget.height).data
    this.particleCtx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)

    this.dots = []

    for (let y = 0; y < this.canvasTarget.height; y += gap) {
      for (let x = 0; x < this.canvasTarget.width; x += gap) {
        const index = (Math.floor(y) * this.canvasTarget.width + Math.floor(x)) * 4
        const red = imageData[index]
        const green = imageData[index + 1]
        const blue = imageData[index + 2]
        const alpha = imageData[index + 3]

        if (alpha > 0) {
          this.dots.push({ x, y, originalX: x, originalY: y, red, green, blue, gap })
        }
      }
    }
  }

  addMouseEffect () {
    this.canvasTarget.addEventListener('mouseenter', this.startHaloAnimation.bind(this))
    this.canvasTarget.addEventListener('mousemove', this.applyForce.bind(this))
    this.canvasTarget.addEventListener('mouseout', this.startResetAnimation.bind(this))
  }

  startHaloAnimation () {
    if (!this.haloDisplayed) {
      this.haloRadius = 0
      this.haloAnimationFrame = window.requestAnimationFrame(this.expandHalo.bind(this))
      this.haloDisplayed = true
    }
  }

  expandHalo () {
    // transition speed
    this.haloRadius += 10
    this.particleCtx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)

    for (const dot of this.dots) {
      const dx = dot.x - this.canvasWidthValue / 2
      const dy = dot.y - this.canvasHeightValue / 2
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance < this.haloRadius) {
        this.particleCtx.fillStyle = `rgb(${dot.red}, ${dot.green}, ${dot.blue})`
        this.particleCtx.beginPath()
        this.particleCtx.arc(dot.x + dot.gap / 2, dot.y + dot.gap / 2, dot.gap / 2, 0, Math.PI * 2)
        this.particleCtx.fill()
      }
    }

    this.ctx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)
    // gradually hide the original image
    this.ctx.globalAlpha = 1 - (this.haloRadius / Math.max(this.canvasWidthValue, this.canvasHeightValue))
    this.ctx.drawImage(this.imageTarget, this.centerX, this.centerY, this.scaledWidth, this.scaledHeight)
    this.ctx.globalAlpha = 1
    this.ctx.drawImage(this.particleCanvas, 0, 0)

    if (this.haloRadius < Math.max(this.canvasWidthValue, this.canvasHeightValue)) {
      this.haloAnimationFrame = window.requestAnimationFrame(this.expandHalo.bind(this))
    }
  }

  applyForce (event) {
    const rect = this.canvasTarget.getBoundingClientRect()
    const mouseX = event.clientX - rect.left
    const mouseY = event.clientY - rect.top
    const forceRadius = 50

    this.particleCtx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)

    for (const dot of this.dots) {
      const dx = dot.x - mouseX
      const dy = dot.y - mouseY
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance < forceRadius) {
        const angle = Math.atan2(dy, dx)
        const force = (forceRadius - distance) / forceRadius
        const randomFactor = Math.random() * 2 - 0.5
        const randomAngleOffset = (Math.random() - 0.5) * Math.PI / 10
        const randomForceOffset = Math.random() * 4

        dot.x += Math.cos(angle + randomAngleOffset) * force * 2 * randomFactor * randomForceOffset
        dot.y += Math.sin(angle + randomAngleOffset) * force * 2 * randomFactor * randomForceOffset
      } else {
        const originalDx = dot.originalX - dot.x
        const originalDy = dot.originalY - dot.y
        dot.x += originalDx * 0.08
        dot.y += originalDy * 0.08
      }

      this.particleCtx.fillStyle = `rgb(${dot.red}, ${dot.green}, ${dot.blue})`
      this.particleCtx.beginPath()
      this.particleCtx.arc(dot.x + dot.gap / 2, dot.y + dot.gap / 2, dot.gap / 2, 0, Math.PI * 2)
      this.particleCtx.fill()
    }

    this.ctx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)
    this.ctx.drawImage(this.particleCanvas, 0, 0)
  }

  startResetAnimation () {
    window.cancelAnimationFrame(this.haloAnimationFrame)
    this.resetAnimationFrame = window.requestAnimationFrame(this.resetDots.bind(this))
  }

  contractHalo () {
    window.cancelAnimationFrame(this.haloAnimationFrame)
    this.particleCtx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)

    for (const dot of this.dots) {
      this.particleCtx.fillStyle = `rgb(${dot.red}, ${dot.green}, ${dot.blue})`
      this.particleCtx.beginPath()
      this.particleCtx.arc(dot.x + dot.gap / 2, dot.y + dot.gap / 2, dot.gap / 2, 0, Math.PI * 2)
      this.particleCtx.fill()
    }

    this.ctx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)
    this.ctx.drawImage(this.particleCanvas, 0, 0)
  }

  resetDots () {
    let allDotsReset = true
    this.particleCtx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)

    for (const dot of this.dots) {
      const dx = dot.originalX - dot.x
      const dy = dot.originalY - dot.y
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance > 1) {
        dot.x += dx * 0.1
        dot.y += dy * 0.1
        allDotsReset = false
      } else {
        dot.x = dot.originalX
        dot.y = dot.originalY
      }

      this.particleCtx.fillStyle = `rgb(${dot.red}, ${dot.green}, ${dot.blue})`
      this.particleCtx.beginPath()
      this.particleCtx.arc(dot.x + dot.gap / 2, dot.y + dot.gap / 2, dot.gap / 2, 0, Math.PI * 2)
      this.particleCtx.fill()
    }

    this.ctx.clearRect(0, 0, this.canvasWidthValue, this.canvasHeightValue)
    this.ctx.drawImage(this.particleCanvas, 0, 0)

    if (!allDotsReset) {
      this.resetAnimationFrame = window.requestAnimationFrame(this.resetDots.bind(this))
    }
  }
}
