import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static values = {
    publishableKey: String,
    price: Number
  }

  async connect () {
    this.stripe = await loadStripe(this.publishableKeyValue, { locale: 'fr' })

    this.mountPaymentMethodMessagingElement()
  }

  mountPaymentMethodMessagingElement () {
    const elements = this.stripe.elements()

    const paymentMessageElement = elements.create('paymentMethodMessaging', {
      amount: this.priceValue,
      currency: 'EUR',
      countryCode: 'FR',
      paymentMethodTypes: ['klarna']
    })
    paymentMessageElement.mount(this.element)
  }
}
