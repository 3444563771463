import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['sizes']

  showSizes (event) {
    const missingSizeController = this.sizesOutlets.find(sizeController => !sizeController.hasSelectedSize())

    if (missingSizeController != null) {
      missingSizeController.show(event)
    }
  }
}
