// Import Rails libraries
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'

// Import all channels
import.meta.glob('../channels/**/*_channel.js', { eager: true })

// Import Stimulus controllers
import '~/storefront/controllers/index.js'

// Import main css
import '~/storefront/css/index.css'

ActiveStorage.start()
