import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clubName: String,
    storefrontLink: String
  }

  connect() {
    if (!navigator.share) {
      this.element.classList.toggle("hidden")
    }
  }

  toggle(event) {
    event.preventDefault()
    if (navigator.share) {
      navigator.share({
        title: this.clubNameValue,
        url: this.storefrontLinkValue
      }).catch(console.error);
    }
  }
}
