import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.displayToast()
  }

  displayToast (event) {
    const toast = this.element

    if (toast) {
      toast.classList.remove('opacity-0', 'hidden')

      setTimeout(() => {
        toast.classList.add('opacity-0', 'hidden')
      }, 3500)
    }
  }
}
