import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['activeThumbnail']
  static targets = ['lightbox', 'lightboxImage', 'originalImage', 'thumbnail']
  static values = {
    open: { type: Boolean, default: false },
    selectedIndex: Number
  }

  show (event) {
    document.querySelector('body').classList.add('overflow-hidden')
    this.lightboxTarget.classList.remove('hidden')
    this.openValue = true

    this.selectImage(event.currentTarget.dataset.index)
  }

  hide () {
    this.lightboxTarget.classList.add('hidden')
    document.querySelector('body').classList.remove('overflow-hidden')
    this.unselectAllThumbnails()
    this.openValue = false
  }

  select (event) {
    event.stopPropagation()
    this.selectImage(event.currentTarget.dataset.index)
  }

  selectImage (index) {
    this.selectedIndexValue = index
    const selectedOriginalImage = this.originalImageTargets.find(el => el.dataset.index === index).querySelector('img')
    const selectedThumbnail = this.thumbnailTargets.find(el => el.dataset.index === index)
    this.lightboxImageTarget.querySelector('img').src = selectedOriginalImage.src
    this.unselectAllThumbnails()
    selectedThumbnail.classList.add(...this.activeThumbnailClasses)
  }

  next () {
    if (!this.openValue) return

    let nextIndex = this.selectedIndexValue + 1
    if (nextIndex >= this.originalImageTargets.length) {
      nextIndex = 0
    }
    this.selectImage(nextIndex.toString())
  }

  previous () {
    if (!this.openValue) return

    let previousIndex = this.selectedIndexValue - 1
    if (previousIndex < 0) {
      previousIndex = this.originalImageTargets.length - 1
    }
    this.selectImage(previousIndex.toString())
  }

  unselectAllThumbnails () {
    this.thumbnailTargets.forEach(el => el.classList.remove(...this.activeThumbnailClasses))
  }
}
