import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scroll"]

  connect() {
    window.addEventListener('load', this.hasMultipleProducts.bind(this))
    this.scrollTarget.addEventListener('scroll', this.isDownTheScroll.bind(this))
  }

  disconnect() {
    this.scrollTarget.removeEventListener('scroll', this.isDownTheScroll.bind(this))
    window.removeEventListener('load', this.hasMultipleProducts.bind(this))
  }

  isDownTheScroll() {
    let scrollTop = this.scrollTarget.scrollTop
    const scrollHeight = this.scrollTarget.scrollHeight
    const clientHeight = this.scrollTarget.clientHeight
    if ((scrollHeight - scrollTop) === clientHeight) {
      this.scrollTarget.classList.add('bottom')
    } else {
      this.scrollTarget.classList.remove('bottom')
    }
  }

  hasMultipleProducts() {
    const scrollHeight = this.scrollTarget.scrollHeight
    const clientHeight = this.scrollTarget.clientHeight
    if ((scrollHeight - clientHeight) !== 0) {
      this.scrollTarget.classList.remove('bottom')
    }
  }
}
