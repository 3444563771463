import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    length: Number
  }

  connect () {
    setTimeout(() => {
      const images = document.querySelectorAll('.product-image')

      for (const image of images) {
        intersectionObserver.observe(image)
      }
    }, 500)
  }
}

const intersectionObserver = new IntersectionObserver(function (entries) {
  for (const entry of entries) {
    const el = entry.target
    const imageIndex = el.getAttribute('data-index')
    const thumbnail = document.querySelector(`.dot[data-carousel-index-param="${imageIndex}"]`)
    if (entry.intersectionRatio > 0.6) {
      thumbnail.classList.replace('bg-gray-300', 'bg-black-100')
    } else if (thumbnail) {
      thumbnail.classList.replace('bg-black-100', 'bg-gray-300')
    }
  }
}, { root: document.body, threshold: 0.6 })
