import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.value = parseInt(this.inputTarget.value, 10)
  }

  increase() {
    this.value++;
    this.inputTarget.removeAttribute("readonly")
    this.inputTarget.value = this.value;
    this.inputTarget.setAttribute("readonly", true)
  }

  decrease() {
    this.value === 1 ? null : this.value--;
    this.inputTarget.removeAttribute("readonly")
    this.inputTarget.value = this.value;
    this.inputTarget.setAttribute("readonly", true)
  }
}
