import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu', 'menuItem', 'select', 'selectItem']
  static values = { selectedSizePrice: Number }

  toggle () {
    const ariaStatus = this.selectTarget.getAttribute('aria-expanded')
    const toggleArray = { false: 'true', true: 'false' }

    this.menuTarget.classList.toggle('hidden')
    this.selectTarget.setAttribute('aria-expanded', toggleArray[ariaStatus])
  }

  hide (event) {
    if (this.selectTarget.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      return
    }
    this.menuTarget.classList.add('hidden')
    this.selectTarget.setAttribute('aria-expanded', 'false')
  }

  update (event) {
    const selectedItem = this.menuItemTargets.find(item => item.contains(event.target))
    if (!selectedItem) return

    this.selectedSizePriceValue = event.target.dataset.price
    this.selectItemTarget.textContent = event.target.value
    this.selectItemTarget.classList.remove('text-black-50')
    this.selectTarget.setAttribute('data-dropdown-selected', true)

    this.menuItemTargets.forEach(item => {
      if (item.querySelector('input')) {
        item.classList.toggle('bg-gray-50', item.querySelector('input').value === event.target.value)
      }
    })
  }

  hasSelectedSize () {
    return this.menuItemTargets.some((item) => {
      if (item.querySelector('input')) {
        return item.querySelector('input').checked
      }
      return false
    })
  }

  show (event) {
    event.stopPropagation()
    if (!this.hasSelectedSize()) {
      event.preventDefault()
      this.toggle()
      this.menuTarget.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth'
      })
    }
  }
}
