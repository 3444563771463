import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }
  threshold = 0.66

  intersectionObserver = new window.IntersectionObserver((entries) => {
    for (const entry of entries) {
      const el = entry.target
      const navId = el.getAttribute('id')
      const navLink = document.querySelector(`a[href="#${navId}"]`)
      if (entry.intersectionRatio > this.threshold) {
        navLink.classList.replace('border-gray-300', 'border-red-100')
        navLink.classList.replace('text-black-60', 'text-black')
      } else if (navLink) {
        navLink.classList.replace('border-red-100', 'border-gray-300')
        navLink.classList.replace('text-black', 'text-black-60')
      }
    }
  }, { root: this.element, threshold: this.threshold })

  connect () {
    setTimeout(() => {
      const contents = document.querySelectorAll('.nav-content')

      for (const content of contents) {
        this.intersectionObserver.observe(content)
      }
    }, 500)
  }

  openModal () {
    document.getElementById(this.idValue).setAttribute('open')
    document.body.classList.add('overflow-hidden')
  }

  closeModal () {
    document.getElementById(this.idValue).removeAttribute('open')
    document.body.classList.remove('overflow-hidden')
  }

  scrollToElement (event) {
    const el = event.params.el
    document.getElementById(el).scrollIntoView({ behavior: 'smooth' })
    event.preventDefault()
  }
}
