import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'logo']

  connect () {
    this.logoTargets.forEach(logo => {
      this.setAspectRatios(logo)
    })
  }

  setAspectRatios (logo) {
    if (logo.complete) {
      this.applyImageStyles(logo.parentNode)
      logo.classList.remove('hidden')
    } else {
      logo.addEventListener('load', () => {
        this.applyImageStyles(logo.parentNode)
        logo.classList.remove('hidden')
      })
    }
  }

  applyImageStyles (container) {
    const img = container.querySelector('[data-logo-banner-target="logo"]')
    const width = img.naturalWidth
    const height = img.naturalHeight
    container.style.setProperty('--base-ratio', width / height)
    container.style.setProperty('--width', width)
    container.style.setProperty('--height', height)
  }
}
