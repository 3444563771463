import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "dropdown", "input"]

  cancelInitials() {
    this.checkboxTarget.checked = false
    this.dropdownTarget.removeAttribute("open")
    this.inputTarget.value = ""
  }

  checkInitials(event) {
    let inputValue = event.target.value
    if (inputValue) {
      this.checkboxTarget.checked = true
    } else {
      this.checkboxTarget.checked = false
    }
  }
}
