import { Controller } from '@hotwired/stimulus'
import { formatCurrency } from '~/common/javascript/utilities.js'

export default class extends Controller {
  static targets = ['logo', 'initials', 'backInitials', 'buttonContent']
  static values = {
    logo: Number,
    initials: Number,
    backInitials: Number,
    buttonPriceSeparator: String
  }
  static outlets = ['sizes']

  connect () {
    this.updatePrice()
  }

  updatePrice () {
    let price = this.sizesOutlet.selectedSizePriceValue

    if (price !== 0 && this.hasButtonContentTarget) {
      if (this.hasLogoTarget && this.logoTarget.checked) price += this.logoValue
      if (this.hasInitialsTarget && this.initialsTarget.checked) price += this.initialsValue
      if (this.hasBackInitialsTarget && this.backInitialsTarget.checked) price += this.backInitialsValue
      this.buttonContentTarget.innerHTML = `${this.buttonPriceSeparatorValue}${formatCurrency(price)}`
    }
  }
}
